export const DefaultPageCuration: any = {
  pharmacology_and_toxicology: 0,
  storage_and_shelf_life: 0,
  manufacturer_and_contact_information: 0,
  domestic_contact_organization: 0,
  dosage_and_administration: 0,
  packaging_and_specifications: 0,
  medication_for_special_populations: 0,
  contraindications: 0,
  precautions: 0,
  adverse_reactions: 0,
  clinical_trials: 0,
  drug_interactions: 0,
  overdose: 0,
  indications_and_usage: 0,
  instructions_for_use_and_operation: 0,
  warning: 0,
  characteristics: 0,
  pharmacokinetics: 0,
  ingredients: 0,
  specifications: 0,
  implementation_standard: 0,
  pharmacodynamics: 0
};

export const DefaultPageCurationFields = [
  { id: 'pharmacology_and_toxicology', labelName: 'Pharmacology and toxicology' },
  { id: 'storage_and_shelf_life', labelName: 'Storage and shelf life' },
  { id: 'manufacturer_and_contact_information', labelName: 'Manufacturer and contact information' },
  { id: 'domestic_contact_organization', labelName: 'Domestic contact organization' },
  { id: 'dosage_and_administration', labelName: 'Dosage and administration' },
  { id: 'packaging_and_specifications', labelName: 'Packaging and specifications' },
  { id: 'medication_for_special_populations', labelName: 'Medication for special populations' },
  { id: 'contraindications', labelName: 'Contraindications' },
  { id: 'precautions', labelName: 'Precautions' },
  { id: 'adverse_reactions', labelName: 'Adverse reactions' },
  { id: 'clinical_trials', labelName: 'Clinical trials' },
  { id: 'drug_interactions', labelName: 'Drug interactions' },
  { id: 'overdose', labelName: 'Overdose' },
  { id: 'indications_and_usage', labelName: 'Indications and usage' },
  { id: 'instructions_for_use_and_operation', labelName: 'Instructions for use and operation' },
  { id: 'warning', labelName: 'Warning' },
  { id: 'characteristics', labelName: 'Characteristics' },
  { id: 'pharmacokinetics', labelName: 'Pharmacokinetics' },
  { id: 'pharmacodynamics', labelName: 'Pharmacodynamics' },
  { id: 'ingredients', labelName: 'Ingredients' },
  { id: 'specifications', labelName: 'Specifications' },
  { id: 'implementation_standard', labelName: 'Implementation standard' }
];
