import { TextField } from '@mui/material';
import React, { useState } from 'react';

const TextFieldCard = ({
  id,
  labelName,
  data,
  setData,
  setDisableSubmit,
  checkValidation = true
}: any) => {
  const [error, setError] = useState<string | null>(null);

  const handleValidation = (value: string) => {
    if (value === '') {
      setError('Field cannot be empty.');
      setData((prevData: object) => ({ ...prevData, [id]: null }));
      setDisableSubmit(true);
      return;
    }

    const intValue = Number(value);
    if (Number.isNaN(intValue)) {
      setError('Please enter a valid number.');
      setDisableSubmit(true);
      return;
    }

    if (intValue === 0) {
      setError('Page number cannot be zero.');
      setDisableSubmit(true);
    } else {
      setError(null);
    }
    setDisableSubmit(false);
    setData((prevData: object) => ({ ...prevData, [id]: intValue }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (checkValidation) {
      handleValidation(value);
    } else {
      setData((prevData: object) => {
        if (value === '') {
          return { ...prevData, [id]: null };
        }
        const intValue = parseInt(value, 10);
        if (Number.isNaN(intValue)) {
          return prevData;
        }
        return { ...prevData, [id]: intValue };
      });
    }
  };

  const getHelperText = () => {
    if (data?.disabled_sections?.includes(id)) {
      return '*Page number is zero as per original page number curation';
    }
    if (error) {
      return error;
    }
    return '';
  };
  return (
    <TextField
      variant='outlined'
      fullWidth
      id={id}
      disabled={data?.disabled_sections?.includes(id)}
      label={labelName}
      placeholder={labelName}
      value={data?.[id]}
      onChange={handleChange}
      error={!!error}
      helperText={getHelperText()}
      InputProps={{
        style: {
          fontSize: '2vh',
          marginBottom: error || data?.disabled_sections?.includes(id) ? 0 : '15px'
        }
      }}
      InputLabelProps={{
        style: { fontSize: '2vh', fontWeight: 'bold' }
      }}
      sx={{
        '& .MuiFormHelperText-root.Mui-disabled': {
          color: !error ? '#FFA500' : undefined, // Change helper text color to warning if error exists
          marginLeft: 0,
          marginBottom: '8px'
        },
        '& .MuiFormHelperText-root': {
          marginLeft: 0,
          marginBottom: '8px'
        }
      }}
    />
  );
};

export default React.memo(TextFieldCard);
