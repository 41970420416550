import { Box, CssBaseline, LinearProgress, Stack, TextField, ThemeProvider } from '@mui/material';
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import themev5 from '../../themev5';
import styles from '../Datagrid/Datagrid.styles';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';
import { getProductList } from '../../api/china';
import Actions from '../../store/actions';
import Store from '../../store';
import Styles from '../Canada/styles/curationdashboard.styles';

const SectionCuration = () => {
  const { dispatch } = useContext<any>(Store);
  const classes = Styles();

  const history = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [primaryNumber, setPrimaryNumber] = useState('');
  const [totalRowCount, setTotalRowCount] = useState(0);
  const rowsPerPageOptions = [10, 20, 50, 100];

  const searchFields = [
    {
      id: 'accession_number',
      label: 'Acceptance Number',
      value: primaryNumber,
      onChange: (value: string) => {
        setPrimaryNumber(value);
      }
    }
  ];
  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getProductList(pageSize, pageNo, primaryNumber);
      if (response === undefined) {
        throw new Error('Result is undefined');
      }
      if (response.data && response.data.Success.data) {
        setIsLoading(false);
        const resultData = response.data.Success.data;
        setData(resultData);
        setTotalRowCount(response.data.Success.count);
      } else {
        setIsLoading(false);
        throw Error('No data');
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    } finally {
      setIsLoading(false);
    }
  }, [pageNo, pageSize, primaryNumber]);

  const DATAGRID_COLUMNS: any = [
    {
      field: 'acceptance_number',
      headerName: 'Acceptance Number',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'trade_name',
      headerName: 'Trade Name',
      width: 350,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params: any) => {
        return <Typography>{params?.row?.drug_data?.generic_name}</Typography>;
      }
    },
    {
      field: 'filename',
      headerName: 'File Name',
      width: 500,
      headerAlign: 'center',
      align: 'left'
    },

    {
      field: 'Edit',
      width: 1000,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: any) => {
        return (
          <Stack direction='row' spacing={2}>
            <Box>
              <Button
                variant={isEmpty(params.row.admin_converted_docs) ? 'outlined' : 'contained'}
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  history.push(
                    `/china/section-curation/convert/${params.row.acceptance_number}?id=${params.row.id}`
                  );
                }}>
                <Typography>Section separation</Typography>
              </Button>
            </Box>
            <Box>
              <Button
                variant={
                  isEmpty(params.row.page_details?.page_number_map_sections)
                    ? 'outlined'
                    : 'contained'
                }
                disabled={isEmpty(params.row.admin_converted_docs)}
                sx={{
                  textTransform: 'none'
                }}
                onClick={() => {
                  history.push(
                    `/china/section-curation/page-number/${params.row.acceptance_number}?id=${params.row.id}`
                  );
                }}>
                <Typography>Section Page number mapping</Typography>
              </Button>
            </Box>
            <Box>
              <Button
                variant={
                  isEmpty(params.row.page_details?.page_number_map) ? 'outlined' : 'contained'
                }
                disabled={isEmpty(params.row.admin_converted_docs)}
                sx={{
                  textTransform: 'none'
                }}
                onClick={() => {
                  history.push(
                    `/china/original-curation/page-number/${params.row.acceptance_number}?id=${params.row.id}`
                  );
                }}>
                <Typography>Original Page number mapping</Typography>
              </Button>
            </Box>
          </Stack>
        );
      }
    }
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        {searchFields.map(row => (
          <TextField
            key={row.id}
            variant='outlined'
            type='search'
            id={row.id}
            label={row.label}
            value={row.value}
            sx={{ paddingRight: '30px' }}
            InputProps={{
              style: { fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }
            }}
            InputLabelProps={{
              style: { fontSize: '1.5vh' }
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPageNo(0);
              row.onChange(event.target.value);
            }}
          />
        ))}
        <Button onClick={fetchData} variant='contained' sx={{ textTransform: 'none' }}>
          <Typography>Search</Typography>
        </Button>
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={data}
          rowCount={totalRowCount}
          columns={DATAGRID_COLUMNS}
          loading={isLoading}
          getRowId={(row: any) => row.id}
          paginationMode='server'
          getRowClassName={() => `table-rows`}
          pageSize={pageSize}
          page={pageNo}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNo(0);
            fetchData();
          }}
          onPageChange={number => {
            setPageNo(number);
            fetchData();
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
          pagination
        />
      </Box>
    </ThemeProvider>
  );
};
export default SectionCuration;
