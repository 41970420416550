import makeRequest from './client';

const baseURL = `/china`;
export const getProductList = (pageSize: number, pageNo: number, acceptanceNumber: string) => {
  const queryParams = [`page=${pageNo}`, `page_size=${pageSize}`];
  if (acceptanceNumber) {
    queryParams.push(`acceptance_number=${acceptanceNumber}`);
  }
  const finalURL = `${baseURL}/page_separation?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getDocx = (resourceId: string, acceptanceNumber: string) => {
  const finalURL = `${baseURL}/section_separation/${resourceId}?acceptance_number=${acceptanceNumber}`;
  return makeRequest(finalURL, 'GET');
};

export const createPdf = (resourceId: any, acceptanceNumber: any, payload: any) => {
  const finalURL = `${baseURL}/section_separation/${resourceId}?acceptance_number=${acceptanceNumber}`;
  return makeRequest(finalURL, 'PUT', payload);
};

export const getPageNumber = (resourceId: string, acceptanceNumber: string) => {
  const finalURL = `${baseURL}/section_mapping/${resourceId}?acceptance_number=${acceptanceNumber}`;
  return makeRequest(finalURL, 'GET');
};

export const updatePageNumber = (resourceId: string, acceptanceNumber: string, data: any) => {
  const finalURL = `${baseURL}/section_mapping/${resourceId}?acceptance_number=${acceptanceNumber}`;
  return makeRequest(finalURL, 'PUT', data);
};
