import { useEffect, useState, memo } from 'react';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import getSurveyAnswers from '../../api/user_survey';

const UserSurvey = () => {
  const [surveyAnswers, setSurveyAnswers] = useState<any>([]);

  useEffect(() => {
    const fetchUserSurveyAnswers = async () => {
      const response = await getSurveyAnswers();

      if (response?.status !== 200 || !response?.data?.body) {
        return;
      }

      setSurveyAnswers(response?.data?.body || []);
    };

    fetchUserSurveyAnswers();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: '#',
      width: 25,
      renderCell: params => params.api.getAllRowIds().indexOf(params.id) + 1
    },
    { field: 'user_id', headerName: 'User Id', width: 65 },
    { field: 'uuid', headerName: 'UUID', width: 200 },
    { field: 'organization', headerName: 'Organization', width: 200 },
    { field: 'survey_id', headerName: 'Survey Id', width: 75 },
    {
      field: 'title',
      headerName: 'Survey Name',
      width: 200,
      valueGetter: params => {
        return params.row?.title?.toUpperCase() ?? 'N/A';
      }
    },
    {
      field: 'answer',
      headerName: 'User Answer',
      flex: 1,
      renderCell: params => {
        const answer = params.row?.answer;
        return (
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {JSON.stringify(answer, null, 2)}
          </pre>
        );
      }
    }
  ];

  return (
    <Box mt={10}>
      <Typography textAlign='center' my={3}>
        User Survey Answers
      </Typography>
      <Box height='80vh' px={2}>
        <DataGrid
          rows={surveyAnswers}
          columns={columns}
          getRowId={row => row.uuid}
          rowSpacingType='margin'
          getRowHeight={() => 'auto'}
        />
      </Box>
    </Box>
  );
};

export default memo(UserSurvey);
