import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  root: {
    alignItems: 'center',
    minWidth: '100%',
    position: 'relative',
    padding: '30px'
  }
}));
