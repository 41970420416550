import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Close, Save } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import styles from '../HPRA/styles/labelSeparation.styles';
import Actions from '../../store/actions';
import Store from '../../store';
import { createPdf, getDocx } from '../../api/china';

const SectionSeparation = () => {
  const { acceptanceNumber } = useParams() as any;
  const { dispatch } = useContext<any>(Store);
  const history = useHistory();
  const location = useLocation();

  const [details, setDetails] = useState<any>(null);
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      window.onbeforeunload = () => {
        return 'Please wait for the data to load...';
      };
      const resourceId: any = new URLSearchParams(location.search).get('id');
      const response = await getDocx(resourceId, acceptanceNumber);
      if (response?.status !== 200 || !response.data.Success) {
        throw new Error('Error: Product Resource not available');
      }
      if (response.data.Success && response.data.Success?.result) {
        setLoading(false);
        setDetails(response.data.Success.result);
      } else {
        setLoading(false);
        throw Error('No data');
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  }, [acceptanceNumber, location]);

  const handleOpenDocEdit = () => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow = window.open(details?.onedrive_file_url, '_blank', option);

    const timer = setInterval(() => {
      if (newWindow?.closed) {
        clearInterval(timer);
        setOpenSubmitConfirmation(true);
      }
    }, 1000);
  };

  const handleOpenOriginalPDF = () => {
    const url = details?.document_link ?? '';
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow = window.open(url, '_blank', option);

    const timer = setInterval(() => {
      if (newWindow?.closed) {
        clearInterval(timer);
        setOpenSubmitConfirmation(true);
      }
    }, 1000);
  };

  const handleSubmitChanges = async () => {
    try {
      const resourceId: any = new URLSearchParams(location.search).get('id');
      setOpenSubmitConfirmation(false);
      setLoading(true);
      const user = await Auth.currentUserInfo();
      const payload = {
        filename: details?.onedrive_doc_name,
        s3_path: details?.s3_path,
        fileId: details?.onedrive_doc_id,
        curator_name: user.attributes['custom:user'],
        curator_email: user.attributes.email
      };
      const res = await createPdf(resourceId, acceptanceNumber, payload);
      if (res?.data?.status !== 200) {
        setLoading(false);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: res.data.message }
        });
      } else if (res.data.Success) {
        setLoading(false);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfully Submitted the File', color: 'success' }
        });
        history.push(`/china/section-curation/page-number/${acceptanceNumber}?id=${resourceId}`);
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    function openDocx() {
      try {
        if (details && details.onedrive_file_url) {
          handleOpenDocEdit();
        } else if (details && !details.onedrive_file_url) {
          dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'No Document Available' }
          });
        }
      } catch (e: any) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    }
    openDocx();
  }, [details]);
  useEffect(() => {
    fetchData();
    return () => {
      window.onbeforeunload = () => {};
    };
  }, []);
  return (
    <div>
      <Container sx={styles.root}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Box display='flex' alignItems='center'>
            <Button
              sx={{ background: '#3AB09E', color: 'whitesmoke' }}
              variant='contained'
              disabled={loading}
              onClick={handleOpenOriginalPDF}>
              View Original PDF
            </Button>
          </Box>
          <IconButton
            aria-label='close'
            onClick={() => {
              history.push('/china/section-curation');
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={2}>
          {loading && (
            <Box
              sx={{
                width: '100%',
                height: '70vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <LinearProgress
                sx={{ width: '100%', maxWidth: '400px', marginBottom: '1em' }}
                color='secondary'
              />
              <Typography sx={{ fontSize: '25px', color: '#3AB09E' }}>
                {!details ? 'Loading data ....' : 'Submitting changes ....'}
              </Typography>

              <Typography sx={{ fontSize: '20px', color: '#3AB09E' }}>
                {details && '(this might take couple of minutes to complete)'}
              </Typography>
            </Box>
          )}
          <Grid item xs={12} lg={12}>
            {!loading && details && details.onedrive_file_url && (
              <Box
                display='flex'
                flexDirection='column'
                minHeight='50vh'
                justifyContent='center'
                alignItems='center'>
                <Typography sx={{ fontSize: '25px', color: '#3AB09E' }}>
                  File is open in a new popup window
                </Typography>
                <Button
                  sx={{ background: '#3AB09E', color: 'whitesmoke' }}
                  onClick={handleOpenDocEdit}
                  variant='contained'
                  disabled={loading}>
                  Open file again.
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <Dialog fullWidth maxWidth='xs' open={openSubmitConfirmation}>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
              Confirm submission
            </Typography>
            <IconButton aria-label='close' onClick={() => setOpenSubmitConfirmation(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
            Are you sure you have edited the document accurately?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={handleOpenDocEdit}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            Open file again
          </Button>
          <Button
            startIcon={<Save />}
            variant='contained'
            onClick={handleSubmitChanges}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default SectionSeparation;
