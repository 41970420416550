import {
  Autocomplete,
  Button,
  Container,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent } from '@material-ui/core';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getSortedLabels, qcStatuses } from '../PMDA/utils';
import Store from '../../store';
import Actions from '../../store/actions';
import TextFieldCard from '../TextFieldCard';
import { getPageNumber, updatePageNumber } from '../../api/china';
import { DefaultPageCurationFields, DefaultPageCuration } from './utils';

const OriginalPageMapping = () => {
  const { dispatch } = useContext<any>(Store);
  const { acceptanceNumber } = useParams() as any;
  const history = useHistory();
  const location = useLocation();
  const [pdfUrl, setPdfUrl] = useState('');
  const [originalPdfUrl, setOriginalPdfUrl] = useState('');
  const [originalSectionMap, setOriginalSectionMap] = useState<any>({});
  const [autoSort, setAutoSort] = useState(true);
  const [qcStatus, setQcStatus] = useState('');
  const [pageCurationFields, setPageCurationFields] = useState(DefaultPageCurationFields);
  const [pageCuration, setPageCuration] = useState<any>(DefaultPageCuration);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleSubmit = async (event: SyntheticEvent) => {
    try {
      event.preventDefault();
      const resourceId: any = new URLSearchParams(location.search).get('id');
      const user = await Auth.currentUserInfo();

      const separation: {
        [k: string]: {
          page_number: number;
        };
      } = {};

      Object.keys(pageCuration).forEach(currentKey => {
        const value = {
          page_number: pageCuration[currentKey],
          heading: pageCurationFields.find(field => field.id === currentKey)?.labelName
        };

        separation[currentKey] = value;
      });

      const payload = {
        sections: originalSectionMap,
        sections_original: separation,
        s3_path: originalPdfUrl,
        qc_status: qcStatus.toLowerCase(),
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user'],
        curation_type: 'original'
      };

      const result = await updatePageNumber(resourceId, acceptanceNumber, payload);

      if (result.status !== 200 || !result?.data || result.data.error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: result.data.message }
        });

        return;
      }

      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: result.data.Success.message, color: 'success' }
      });
      history.push('/china/section-curation');
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: (error as Error).message }
      });
    } finally {
      setDisableSubmit(false);
    }
  };
  useEffect(() => {
    const addNewFields = (newSections: string[]) => {
      const defaultSections = pageCurationFields.map(currentSection => {
        return currentSection.id;
      });

      const unmatchedNewSections = newSections.filter(
        currentSection => !defaultSections.includes(currentSection)
      );

      if (!unmatchedNewSections || unmatchedNewSections.length < 1) {
        return;
      }

      setPageCurationFields([...pageCurationFields]);
    };

    const getProductResource = async () => {
      try {
        const resourceId: any = new URLSearchParams(location.search).get('id');
        const result = await getPageNumber(resourceId, acceptanceNumber);

        if (result.status !== 200 || !result?.data?.Success) {
          throw new Error('Error: Product Resource not available');
        }

        setQcStatus(result.data.Success.qc_status);
        setPdfUrl(result.data.Success.original_pdf_url);
        setOriginalPdfUrl(result.data.Success.original_s3_path);
        if (isEmpty(result.data.Success.page_number_map)) {
          setPageCuration(DefaultPageCuration);
          return;
        }

        const previousCuration: any = {};
        Object.entries(result.data.Success.page_number_map).forEach(([key, value]: any) => {
          previousCuration[key] = value.page_number;
        });
        setOriginalSectionMap(result.data.Success.page_number_map_sections);
        const newSections: {
          [k: string]: number;
        } = {};

        const validKeys = Object.fromEntries(
          Object.entries(previousCuration).filter(([key]) => !/undefined/.test(key))
        );

        if (validKeys) {
          Object.keys(validKeys).forEach(key => {
            newSections[key] = previousCuration[key];
          });

          addNewFields(Object.keys(validKeys));
        }
        // make sure that setPageCuration only has valid keys that are in DefaultPageCuration
        const validSections: any = Object.fromEntries(
          Object.entries(newSections).filter(([key]) =>
            Object.keys(DefaultPageCuration).includes(key)
          )
        );
        setPageCuration(validSections);
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: (error as Error).message }
        });
      }
    };

    getProductResource();
  }, []);

  return (
    <Container sx={{ overflow: 'hidden', mt: 10 }}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography
          component='p'
          variant='h5'
          style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
          Original page mapping
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={autoSort}
                onChange={() => {
                  setAutoSort(!autoSort);
                }}
              />
            }
            label='Auto sorting'
          />
        </Grid>
        <IconButton
          aria-label='close'
          onClick={() => {
            history.push('/china/section-curation');
          }}
          sx={{
            position: 'absolute',
            right: 5,
            top: 2,
            color: 'gray.light'
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} display='flex'>
          <Grid item xs={12} lg={6} mb={3} sx={{ overflow: 'auto', height: '70vh', padding: 2 }}>
            <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {(autoSort
                    ? getSortedLabels(pageCurationFields, pageCuration)
                    : pageCurationFields
                  ).map(currentField => {
                    return (
                      <TextFieldCard
                        key={currentField.id}
                        id={currentField.id}
                        labelName={currentField.labelName}
                        data={pageCuration}
                        setData={setPageCuration}
                        setDisableSubmit={setDisableSubmit}
                      />
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id='qc_status'
                    fullWidth
                    options={qcStatuses}
                    value={qcStatus}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setQcStatus(newValue);
                      }
                    }}
                    sx={{ fontSize: '2vh' }}
                    renderInput={params => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <TextField {...params} label='QC Status' variant='outlined' required />
                    )}
                  />
                </Grid>

                <Grid item xs={12} display='flex' direction='column'>
                  <Button
                    type='submit'
                    fullWidth
                    disabled={disableSubmit}
                    variant='contained'
                    color='primary'
                    style={{ color: 'white' }}>
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ height: '70vh' }}>
            <iframe
              src={pdfUrl}
              title='Product Resource'
              style={{ height: '100%', width: '100%' }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Container>
  );
};
export default OriginalPageMapping;
