import SectionCuration from '../components/China/SectionCuration';
import SectionSeparation from '../components/China/SectionSeparation';
import SectionPageMapping from '../components/China/SectionPageMapping';
import OriginalPageMapping from '../components/China/OriginalPageMapping';

const CHINA_BASE_ROUTE = '/china';

const CHINA_ROUTES = [
  {
    id: 'china',
    path: `${CHINA_BASE_ROUTE}/section-curation`,
    component: SectionCuration,
    exact: true
  },
  {
    id: 'chinaConvert',
    path: `${CHINA_BASE_ROUTE}/section-curation/convert/:acceptanceNumber`,
    component: SectionSeparation,
    exact: true
  },
  {
    id: 'chinaPageNumber',
    path: `${CHINA_BASE_ROUTE}/section-curation/page-number/:acceptanceNumber`,
    component: SectionPageMapping
  },
  {
    id: 'chinaPageNumberOriginal',
    path: `${CHINA_BASE_ROUTE}/original-curation/page-number/:acceptanceNumber`,
    component: OriginalPageMapping
  }
];

export default CHINA_ROUTES;
