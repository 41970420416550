import React, { useContext, useState } from 'react';

import {
  Container,
  DialogTitle,
  Box,
  Grid,
  IconButton,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Store from '../../../store';
import Actions from '../../../store/actions';
import { getPDFSignedUrl } from '../../../api/Cder';
import Styles from './PediatricIndications.styles';

const CHECK_BOX_OPTIONS = ['Newborn', 'Infant', 'Child', 'Adolescent'];

const PediatricIndicationAgeCategory = ({ closeEdit, onFormSubmit, indicationData }: any) => {
  const { dispatch } = useContext<any>(Store);
  const classes = Styles();
  const [status, setstatus] = useState(indicationData.status);
  const [pdfUrl, setPdfUrl] = useState('');
  const [categories, setCategories] = useState(indicationData.pediatrics_categories);
  const { applno, submissiontype, submissionno, activeingredient, drugname } =
    indicationData?.appl_details || {};

  const details = [
    { label: 'Application Details', content: `${applno}-${submissiontype}-${submissionno}` },
    { label: 'Generic Name', content: activeingredient },
    { label: 'Product Name', content: drugname }
  ];

  const handleCheckBoxChange = (opt: string) => {
    if (opt === 'None') {
      setCategories(null);
    } else {
      const foundIndex = (categories || []).findIndex((cat: string) => cat === opt);
      const newCategories = categories ? [...categories] : [];
      if (foundIndex === -1) {
        newCategories.push(opt);
      } else {
        newCategories.splice(foundIndex, 1);
      }
      setCategories(newCategories);
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    onFormSubmit({
      ...indicationData,
      pediatrics_categories: categories,
      status
    });
  };

  const handlePDFClick = async () => {
    try {
      const path = indicationData?.appl_details?.s3_path;
      const payload = {
        s3_path: path.startsWith('/') ? path.slice(1) : path
      };
      const result = await getPDFSignedUrl(payload);
      if (result === undefined) {
        throw new Error('Result is undefined');
      }
      if (result.data && result.data.body.pdf_url) {
        setPdfUrl(result.data.body.pdf_url);
      } else {
        throw Error('No data');
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  return (
    <Container className={classes.root}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Select Age Categories
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={2} display='flex' overflow='hidden' p={1}>
          {pdfUrl && (
            <Grid item xs={12} lg={4}>
              <Box display='flex' justifyContent='center'>
                <iframe
                  title='pdf'
                  src={pdfUrl}
                  style={{
                    height: '75vh',
                    width: '50vw',
                    marginTop: '20px',
                    position: 'relative'
                  }}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} lg={pdfUrl ? 4 : 6} overflow='auto'>
            <Stack spacing={1}>
              {details.map(({ label, content }) => (
                <Stack key={label} maxHeight='fit-content' overflow='auto'>
                  <Typography>
                    <b>{label}:</b> {content}
                  </Typography>
                </Stack>
              ))}
              <Stack maxHeight={400} overflow='auto'>
                <Button
                  type='submit'
                  onClick={handlePDFClick}
                  variant='contained'
                  color='primary'
                  style={{ color: 'white', width: 'fit-content' }}>
                  Open PDF
                </Button>
              </Stack>
              <Stack maxHeight={400} overflow='auto'>
                <Typography fontWeight='bold'>Indication:</Typography>
                <Typography pt={2} pb={2} pl={1}>
                  {indicationData?.indications}
                </Typography>
              </Stack>

              {indicationData.dosage_and_administration && (
                <Stack maxHeight={400} overflow='auto'>
                  <Typography fontWeight='bold'>Dosage and Administration</Typography>
                  <Typography pt={2} pb={2} pl={1}>
                    {indicationData?.dosage_and_administration}
                  </Typography>
                </Stack>
              )}

              {indicationData.use_in_specific_populations && (
                <Stack maxHeight={400} overflow='auto'>
                  <Typography fontWeight='bold'> Use in specific populations </Typography>
                  <Typography pt={2} pb={2} pl={1}>
                    {indicationData?.use_in_specific_populations}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} lg={pdfUrl ? 4 : 6} overflow='hidden'>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography fontWeight='bold'>Categories:</Typography>
                  <Box>
                    {CHECK_BOX_OPTIONS.map((opt: string) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={categories && !!categories.find((cat: string) => cat === opt)}
                            onChange={() => handleCheckBoxChange(opt)}
                          />
                        }
                        label={opt}
                      />
                    ))}

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!categories || categories.length === 0}
                          onChange={() => handleCheckBoxChange('None')}
                        />
                      }
                      label='None'
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    id='status'
                    label='QC Status'
                    select
                    fullWidth
                    required
                    value={status}
                    onChange={e => setstatus(e.target.value)}
                    InputProps={{
                      style: { fontSize: 16, minWidth: '250px' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: 16 }
                    }}>
                    <MenuItem value='Qced'>Qced</MenuItem>
                    <MenuItem value='Escalate'>Escalate</MenuItem>
                    <MenuItem value='no_change'>No Change</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} display='flex' direction='row' justifyContent='space-around'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{ color: 'white', width: '45%' }}>
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default React.memo(PediatricIndicationAgeCategory);
