import Label from '../components/FDA/Label';
import LabelSection from '../components/FDA/LabelSection';
import QCDocumentCheckLabel from '../components/FDA/QCDocumentCheckLabel';
import LabelSectionEditor from '../components/FDA/LabelSectionEditor';
import Fda from '../components/FDA/Fda';
import QCDocumentCheck from '../components/DataEntry/QCDocumentCheck';
import QCDocumentCheckFda from '../components/FDA/QCDocumentCheckFda';
import CderATC from '../components/FDA/CderATC';
import LabelCuration from '../components/FDA/LabelCuration';
import LetterCuration from '../components/FDA/LetterCuration';
import PediatricsIndicationTable from '../components/FDA/PediatricsIndicationTable';
import InvalidFileUrlCuration from '../components/FDA/InvalidFileUrlCuration';
import FormulationCuration from '../components/FDA/FormulationCuration';
import RouteCuration from '../components/FDA/RouteCuration';
import PediatricsIndicationDatagrid from '../components/FDA/PediatricsIndications/PediatricsIndicationDatagrid';

const FDA_ROUTES = [
  {
    id: 'aaBtFtCuration',
    path: '/fda',
    component: Fda,
    exact: true
  },
  {
    id: 'aaBtFtCurationReview',
    path: '/fda/:applNo/:subType/:subNo/:docType',
    component: QCDocumentCheckFda,
    exact: true
  },
  {
    id: 'labelTextQc',
    path: '/fda/label',
    component: Label,
    exact: true
  },
  {
    id: 'labelSection',
    path: '/fda/label/section',
    component: LabelSection,
    exact: true
  },
  {
    id: 'labelSectionPage',
    path: '/fda/label_section/:applNo/:subType/:subNo/:docType',
    component: QCDocumentCheck,
    exact: true
  },
  {
    id: 'labelTextReview',
    path: '/fda/label/:applNo/:subType/:subNo/:docType',
    component: QCDocumentCheckLabel,
    exact: true
  },
  {
    id: 'labelTextReview',
    path: '/fda/label/section/:applNo/:subType/:subNo/:docType',
    component: LabelSectionEditor,
    exact: true
  },
  {
    id: 'fdaAtcCuration',
    path: '/fda/atc',
    component: CderATC,
    exact: true
  },
  {
    id: 'cderLabelCuration',
    path: '/cder/label',
    component: LabelCuration,
    exact: true
  },
  {
    id: 'cderLetterCuration',
    path: '/cder/letter',
    component: LetterCuration,
    exact: true
  },
  {
    id: 'cderLetterCuration',
    path: '/cder/pediatrics',
    component: PediatricsIndicationTable,
    exact: true
  },
  {
    id: 'cderPediatric',
    path: '/cder/pediatrics-indications',
    component: PediatricsIndicationDatagrid,
    exact: true
  },
  {
    id: 'invalidFileUrl',
    path: '/cder/invalid-files',
    component: InvalidFileUrlCuration,
    exact: true
  },
  {
    id: 'formulationCuration',
    path: '/cder/formulation',
    component: FormulationCuration,
    exact: true
  },
  {
    id: 'routeCuration',
    path: '/cder/route',
    component: RouteCuration,
    exact: true
  }
];

export default FDA_ROUTES;
