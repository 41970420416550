/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// muiv5
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@mui/icons-material/Search';

// Custom Components
import VivproDatagrid from '../Datagrid';

// styles
import styles from './styles/ApplicationTableView.styles';

// api
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { getKeyList } from '../../api/regulatory_information';
import { Button, FormControl, InputLabel } from '@mui/material';

export interface KeyRecordProps {
  applno: string;
  key: string;
  submission_type: string;
  submission_number: string;
  id: string;
  status: string;
  priority: string;
  center: string;
}

const qcStatus = ['All Documents', 'ToQC', 'QCed'];
const priorityKeys = ['All', 'Low', 'Medium', 'High'];
const centerKeys = ['All', 'CBER', 'CDER'];
const submissionType = ['All', 'ORIG', 'SUPPL'];

const ApplicationTableView = ({ docType }: { docType: string }) => {
  const history = useHistory();
  const [searchData, setSearchData] = useState<KeyRecordProps[]>([]);
  const [keyData, setKeyData] = useState<KeyRecordProps[]>([]);
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState('ToQC');
  const [selectedPriority, setPriority] = useState('Low');
  const [selectedCenter, setCenter] = useState('All');
  const [selectedSubmissionType, setSelectedSubmissionType] = useState('All');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        setLoading(true);
        const response = await getKeyList();
        if (response.data.Success.length >= 1) {
          setKeyData(response.data.Success);
          setSearchData(response.data.Success);
        } else {
          setKeyData([]);
          setSearchData([]);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('No record Found');
      }
      setLoading(false);
    }
    getData();
  }, []);

  const filterData = (
    selectStatus: string,
    text: string,
    data: KeyRecordProps[],
    selectedPriority: string,
    selectedCenter: string,
    selectedSubmissionType: string
  ) => {
    let filteredData = data;

    // Filter by status
    if (selectStatus !== 'All Documents') {
      filteredData = filteredData.filter((dt: { status: string }) =>
        dt.status.toLowerCase().includes(selectStatus.trim().toLowerCase())
      );
    }

    // Filter by search text
    if (text) {
      filteredData = filteredData.filter((dt: { key: string }) =>
        dt.key.toLowerCase().includes(text.trim().toLowerCase())
      );
    }

    // Filter by priority
    if (selectedPriority !== 'All') {
      filteredData = filteredData.filter((dt: { priority: string }) =>
        dt.priority.toLowerCase().includes(selectedPriority.trim().toLowerCase())
      );
    }

    // Filter by center
    if (selectedCenter !== 'All') {
      filteredData = filteredData.filter((dt: { center: string }) =>
        dt.center.toLowerCase().includes(selectedCenter.trim().toLowerCase())
      );
    }

    if (selectedSubmissionType !== 'All') {
      filteredData = filteredData.filter((dt: { submission_type: string }) =>
        dt.submission_type.toLowerCase().includes(selectedSubmissionType.trim().toLowerCase())
      );
    }

    setSearchData(filteredData);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    const selectedStatus = event.target.value as string;
    setStatus(selectedStatus);
    filterData(
      selectedStatus,
      searchText,
      keyData,
      selectedPriority,
      selectedCenter,
      selectedSubmissionType
    );
  };

  const handlePriorityChange = (event: SelectChangeEvent) => {
    const selectedPriority = event.target.value as string;
    setPriority(selectedPriority);
    filterData(
      status,
      searchText,
      keyData,
      selectedPriority,
      selectedCenter,
      selectedSubmissionType
    );
  };

  const handleCenterChange = (event: SelectChangeEvent) => {
    const selectedCenter = event.target.value as string;
    setCenter(selectedCenter);
    filterData(
      status,
      searchText,
      keyData,
      selectedPriority,
      selectedCenter,
      selectedSubmissionType
    );
  };

  const handleSubmissionTypeChange = (event: SelectChangeEvent) => {
    const submissionType = event.target.value as string;
    setSelectedSubmissionType(submissionType);
    filterData(
      status,
      searchText,
      keyData,
      selectedPriority,
      selectedCenter,
      submissionType
    );
  };

  // Column Mappings
  const COLUMNS_MAPPINGS = [
    {
      field: 'Edit',
      width: 100,
      flex: 0.1,
      headerAlign: 'center',
      // eslint-disable-next-line no-unused-vars
      renderCell: ({ row }: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push(`/regulatoryinformation/${row.center}/${row.key}`);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'key',
      headerName: 'Application Key',
      headerAlign: 'center',
      width: 200,
      flex: 0.2,
      align: 'center'
    },
    {
      field: 'applno',
      headerName: 'Application Number',
      headerAlign: 'center',
      width: 200,
      flex: 0.2,
      align: 'center'
    },
    {
      field: 'center',
      headerName: 'Center',
      headerAlign: 'center',
      width: 200,
      flex: 0.1,
      align: 'center'
    },
    {
      field: 'submission_type',
      headerName: 'Submission type',
      headerAlign: 'center',
      width: 200,
      flex: 0.2,
      align: 'center'
    },
    {
      field: 'submission_number',
      headerName: 'Submission Number',
      headerAlign: 'center',
      width: 200,
      flex: 0.2,
      align: 'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      width: 200,
      flex: 0.1,
      align: 'center'
    },
    {
      field: 'priority',
      headerName: 'Priority',
      headerAlign: 'center',
      width: 200,
      flex: 0.1,
      align: 'center'
    }
  ];

  return (
    <Box height='10vh'>
      <Box style={{ height: '50vh', width: '95%', paddingTop: '10vh', paddingLeft: '10vh' }}>
        <Box>
          <Typography sx={styles.Typography}>Regulatory Information</Typography>
        </Box>
        <Box sx={styles.root} pr={2}>
          <Stack direction='column' alignItems='flex-start' spacing={2}>
            <Stack direction='row' spacing={2} pt={3} width='100%'>
              <TextField
                variant='outlined'
                type='search'
                id='search_application'
                label='Search Application Number'
                value={searchText}
                InputProps={{
                  style: { fontSize: '1.5vh', minWidth: '250px' },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  style: { fontSize: '1.5vh' }
                }}
                onChange={e => {
                  setSearchText(e.target.value);
                  filterData(
                    status,
                    e.target.value,
                    keyData,
                    selectedPriority,
                    selectedCenter,
                    selectedSubmissionType
                  );
                }}
              />
              <FormControl>
                <InputLabel id='status'>Status</InputLabel>
                <Select
                  labelId='qc_status'
                  name='qc_status'
                  id='qc_status'
                  value={status}
                  label='Status'
                  sx={styles.select}
                  onChange={handleStatusChange}>
                  {qcStatus?.map(option => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {/* Priority Filter */}
              <FormControl>
                <InputLabel id='priority'>Priority</InputLabel>
                <Select
                  labelId='priority'
                  name='priority'
                  id='priority'
                  value={selectedPriority}
                  label='Priority'
                  sx={styles.select}
                  onChange={handlePriorityChange}>
                  {priorityKeys?.map(option => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {/* Center Filter */}
              <FormControl>
                <InputLabel id='center'>Center</InputLabel>
                <Select
                  labelId='center'
                  name='center'
                  id='center'
                  value={selectedCenter}
                  label='Center'
                  sx={styles.select}
                  onChange={handleCenterChange}>
                  {centerKeys?.map(option => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {/* Submission Type Filter */}
              <FormControl>
                <InputLabel id='center'>Submission Type</InputLabel>
                <Select
                  labelId='submission_type'
                  name='submission_type'
                  id='submission_type'
                  value={selectedSubmissionType}
                  label='Submission Type'
                  sx={styles.select}
                  onChange={handleSubmissionTypeChange}>
                  {submissionType?.map(option => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            <Box height='70vh' width='100%'>
              <VivproDatagrid
                rows={searchData}
                loading={loading}
                key={`${docType}-datagrid`}
                columnsMapping={COLUMNS_MAPPINGS as any}
                rowId='id'
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ApplicationTableView;
