import makeRequest from './client';

const baseURL = `/user_survey`;

const getSurveyAnswers = () => {
  const finalURL = `${baseURL}/answers`;
  return makeRequest(finalURL, 'GET');
};

export default getSurveyAnswers;
